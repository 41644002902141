import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Link } from "react-router-dom";
import ImgSolution1 from "../../assets/images/hh/solutions/Three Main Solution-06.jpg";
import ImgSolution2 from "../../assets/images/hh/solutions/Three Main Solution-07.jpg";
import ImgSolution3 from "../../assets/images/hh/solutions/Three Main Solution-08.jpg";

const dataList = [
  {
    image: "/images/service/serviice-01.jpg",
    title: "Awarded Design",
    description:
      "There are many variations variats  of passages of Lorem Ipsum available.",
  },
  {
    image: "/images/service/serviice-02.jpg",
    title: "Design & Creative",
    description:
      "Passages there are many variatin Lorem Ipsum available have suffered.",
  },
];

const TabSolution = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-12">
                <div className="rn-default-tab style-three">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>First</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Second</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Third</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    {/* TabPanel 1 */}
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">
                                  Accessibility to unlimited working capital
                                </h4>
                                <p>
                                  Financial sources consists of Home Investor
                                  and Trust Funds.
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                <div className="thumbnail image-left-content">
                                  <img src={ImgSolution1} alt="split Images" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/* TabPanel 2 */}
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">Demand Generator</h4>
                                <p>
                                  Home The Platform that manage home buyers’
                                  databases’, interactions, house preferences,
                                  and everything that pertains to your home
                                  buyers details, including leads and
                                  satisfaction level.
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                <div className="thumbnail image-left-content">
                                  <img src={ImgSolution2} alt="split Images" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>

                    {/* TabPanel 3 */}
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <div className="row align-items-center row--30">
                            <div className="col-lg-4">
                              <div className="section-title">
                                <h4 className="title">
                                  Scalable, fast-standardized unit supply
                                </h4>
                                <p>
                                  Property developers consists of In-house
                                  developer and empowered land owner as
                                  developer partners.
                                </p>
                              </div>
                            </div>
                            <div className="col-lg-8 mt_md--30 mt_sm--30">
                              <div className="row row--15 mt_dec--30 service-wrapper">
                                <div className="thumbnail image-left-content">
                                  <img src={ImgSolution3} alt="split Images" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabSolution;
