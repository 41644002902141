import React from "react";
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const teamData = [
  {
    image: "https://i.ibb.co/VLXf8sB/Nick-Standish.png",
    name: "Nick Standish",
    position: "Board of Advisors",
    // location: "CO Miego, AD, USA",
    description: [
      {
        detail:
          "Canadian investor, executive and entrepreneur with 15 years of investment, corporate finance and business experience in North America, South America, Europe and Asia in a wide variety of industries including financial services, digital assets, fintech, oil & gas, natural resources & alternative medicine.",
      },
      {
        detail:
          "Currently serve as Executive Director of Marina Bay Capital, Marina Bay Capital Americas and Director of Stamatis Ventures.",
      },
    ],
    socialNetwork: [
      {
        icon: <FiLinkedin />,
        url: "#",
      },
    ],
  },
  {
    image: "https://i.ibb.co/HhfWtT5/Alber-Fan.png",
    name: "Albert Fan",
    position: "Board of Advisors",
    // location: "Bangladesh",
    description: [
      {
        detail:
          "40+ years of experience as a business veteran with both regional corporate leadership background in IBM, DHL and his own businesses.",
      },
      {
        detail:
          "He was the Lead Assessor in the IBM Market Driven Quality Program and had set up the IBM ASEAN Parts Distribution Centre. He was also the Asia Pacific and Middle East Director at DHL. Albert was a Directorate member of the Singapore Civil Aviation Authority of Singapore.",
      },
    ],
    socialNetwork: [
      {
        icon: <FiLinkedin />,
        url: "#",
      },
    ],
  },
  {
    image: "https://i.ibb.co/XxwN9My/Charlie-In.png",
    name: "Charlie In",
    position: "Board of Advisors",
    // location: "Bangladesh",
    description: [
      {
        detail:
          "40+ years of experience covering sales, marketing, operations, finance, restructuring, M&A, capital sourcing, investments, divestment, IPOs and reverse-takeovers. He was instrumental in arranging the public listing and fund raising for several Chinese companies outside China.",
      },
      {
        detail:
          "He has taken the oversight and governance role in Asia AgriTech Fund, Asia Angel Investors Foundation, China Pre-IPO Equities Fund and MFund.",
      },
    ],
    socialNetwork: [
      {
        icon: <FiLinkedin />,
        url: "#",
      },
    ],
  },
];

const TeamOne = ({ column, teamStyle }) => {
  return (
    <div className="row row--15 justify-content-md-center">
      {teamData.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`rn-team ${teamStyle}`}>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className="inner">
                <figure className="thumbnail">
                  <img src={data.image} alt="Management" />
                </figure>
                <figcaption className="content">
                  <h2 className="title">{data.name}</h2>
                  <h6 className="subtitle theme-gradient">{data.position}</h6>
                  {data.location ? (
                    <span className="team-form">
                      <img src="./images/team/location.svg" alt="Management" />
                      <span className="location">{data.location}</span>
                    </span>
                  ) : (
                    ""
                  )}
                  {data.description.map((desc, index) => (
                    <p className="description text-start mb--20" key={index}>
                      {desc.detail}
                    </p>
                  ))}

                  {/* <ul className="social-icon social-default icon-naked mt--20">
                    {data.socialNetwork.map((social, index) => (
                      <li key={index}>
                        <a href={`${social.url}`}>{social.icon}</a>
                      </li>
                    ))}
                  </ul> */}
                </figcaption>
              </div>
            </ScrollAnimation>
          </div>
        </div>
      ))}
    </div>
  );
};

export default TeamOne;
