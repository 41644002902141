import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "https://i.ibb.co/1dVCfxy/Asset-Image-01.png",
    title: "Reg D Portal",
    description: "For US Accredated Investors.",
  },
  {
    image: "https://i.ibb.co/fQLtCQJ/Asset-Image-02.png",
    title: "Reg S Portal",
    description: "For Non-US Accredated Investors.",
  },
];
const ServiceThreeRegD = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper justify-content-center">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                  <img src={val.image} alt="card Images" />
                </div>
                <div className="content">
                  <h4 className="title">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceThreeRegD;
