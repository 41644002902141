import React from "react";
import { Link } from "react-router-dom";

const Nav = () => {
  return (
    <ul className="mainmenu">
      <li>
        <a href="#Background">Background</a>
        {/* <Link to="#background">Background</Link> */}
      </li>

      <li>
        <a href="#Framework">Framework</a>
        {/* <Link to="#framework">Framework</Link> */}
      </li>

      <li>
        <a href="#Management">Management</a>
        {/* <Link to="#Management">Management</Link> */}
      </li>

      <li>
        <a href="#Target">Target</a>
        {/* <Link to="#Target">Target</Link> */}
      </li>

      <li>
        <a href="#FAQ">FAQ</a>
        {/* <Link to="#FAQ">FAQ</Link> */}
      </li>

      <li>
        <a href="#Attachment">Attachment</a>
        {/* <Link to="#Attachment">Attachment</Link> */}
      </li>
    </ul>
  );
};
export default Nav;
