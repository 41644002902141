import React from "react";
import Slider from "react-slick";
import SEO from "../common/SEO";
import HeaderOne from "../common/header/HeaderOne";
import { BannerActivation } from "../utils/script";
import Separator from "../elements/separator/Separator";
import CircleProgress from "../elements/progressbar/CircleProgress";
import PortfolioOne from "../elements/portfolio/PortfolioOne";
import ServiceFour from "../elements/service/ServiceFour";
import TimelineTwo from "../elements/timeline/TimelineTwo";
import SectionTitle from "../elements/sectionTitle/SectionTitle";
import FooterTwo from "../common/footer/FooterTwo";
import BlogList from "../components/blog/itemProp/BlogList";
import BlogClassicData from "../data/blog/BlogList.json";
import ServiceOne from "../elements/service/ServiceOne";
import CounterUpFour from "../elements/counterup/CounterUpFour";
import TeamOne from "../elements/team/TeamOne";
import AccordionOne from "../elements/accordion/AccordionOne";
import AttachmentCard from "../elements/team/AttachmentCard";
import TeamTwo from "../elements/team/TeamTwo";
import { Link } from "react-router-dom";
import TabOne from "../elements/tab/TabOne";
import ServiceThree from "../elements/service/ServiceThree";
import ServiceThreeInvestFlow from "../elements/service/ServiceThreeInvestFlow";
import AboutFour from "../elements/about/AboutFour";
import { FiArrowRight } from "react-icons/fi";
import ScrollAnimation from "react-animate-on-scroll";
import TabTwo from "../elements/tab/TabTwo";
import ServiceThreeRegD from "../elements/service/ServiceThreeRegD";

import { slickDot } from "../utils/script";

// image import
import ImgSupplyDemand from "../assets/images/hh/challenges/supplydemandfull.png";
import ImgSupply from "../assets/images/hh/challenges/supply.png";
import ImgDemand from "../assets/images/hh/challenges/Demand.png";
import ImgProblemFull from "../assets/images/hh/problems/Dekstop_The Problems-01.jpg";
import ImgProblemMobile1 from "../assets/images/hh/problems/Mobile_The Problems-02.jpg";
import ImgProblemMobile2 from "../assets/images/hh/problems/Mobile_The Problems-03.jpg";
import ImgHowHH1 from "../assets/images/hh/howHH/ecosystem-02.png";
import ImgHowHH2 from "../assets/images/hh/howHH/ecosystem-03.png";
import ImgStructure from "../assets/images/hh/structure/Corp structure-04-04.png";
import ImgMilestoneFull from "../assets/images/hh/milestone/mil_full.jpg";
import ImgMilestone1 from "../assets/images/hh/milestone/mil_1.jpg";
import ImgMilestone2 from "../assets/images/hh/milestone/mil_2.jpg";
import imgBusinesFlow from "../assets/images/hh/businessFlow/businessFlow.png";
import MissionStatementFull from "../assets/images/hh/missionStatement/mission statement.jpg";
import MissionStatementMobile from "../assets/images/hh/missionStatement/mission statement_mobile.jpg";
import CrisisFull from "../assets/images/hh/crisis/problem.jpg";
import Crisis1 from "../assets/images/hh/crisis/Part_Happyhomes business flow-02.png";
import Crisis2 from "../assets/images/hh/crisis/Part_Happyhomes business flow-03.png";
import Crisis3 from "../assets/images/hh/crisis/Part_Happyhomes business flow-04.png";
import SplitCrisis from "../elements/split/SplitCrisis";
import SplitMngmnt1 from "../elements/split/SplitMngmnt1";
import SplitMngmnt2 from "../elements/split/SplitMngmnt2";
import TabSolution from "../elements/tab/TabSolution";

var BlogListData = BlogClassicData.slice(0, 3);

const BannerData = [
  {
    image: "https://i.ibb.co/qD7ZQcP/background-web-ipo-1.jpg",
    title: "HappyHomes is going to Nasdaq",
    description: "This page is intentionally prepared for Pre-IPO Fundraising.",
  },
  //   {
  //     image: "/images/bg/bg-image-18.jpg",
  //     title: "Startup Your <br /> Creative Agency.",
  //     description:
  //       "We help our clients succeed by creating brand identities, <br /> digital experiences, and print materials.",
  //   },
];

const LandingPage = () => {
  return (
    <>
      <SEO title="HappyHomes" />
      <main className="page-wrapper">
        {/* Start Navbar Area  */}
        <HeaderOne
          btnStyle="btn-small btn-icon round"
          HeaderSTyle="header-transparent"
        />
        {/* End Navbar Area  */}

        {/* Start Header / Cover Area  */}
        <Slider
          className="slider-area slider-style-4 variation-2 slider-dot rn-slick-dot rn-slick-arrow"
          {...BannerActivation}
        >
          {BannerData.map((data, index) => (
            <div key={index} className="single-slide">
              <div
                className="height-950 bg-overlay bg_image"
                style={{
                  //   backgroundImage: `url(${process.env.PUBLIC_URL} ${data.image})`,
                  backgroundImage: `url(${data.image})`,
                }}
              >
                <div className="container">
                  <div className="row row--30 align-items-center">
                    <div className="col-lg-12">
                      <div className="inner text-center">
                        <h1
                          className="title"
                          dangerouslySetInnerHTML={{ __html: data.title }}
                        ></h1>
                        <p
                          className="description"
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                        <div className="button-group mt--30">
                          <a
                            className="btn-default btn-large round"
                            target="_blank"
                            href="https://calendly.com/happyhomesqna/30min"
                          >
                            Register to Q&A Session
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        {/* End Header / Cover Area  */}

        <ScrollAnimation
          animateIn="fadeInUp"
          animateOut="fadeInOut"
          animateOnce={true}
        >
          <div className="rwt-portfolio-area rn-section-gapTop">
            <div className="container-fluid plr--60 plr_md--15 plr_sm--15">
              <div className="thumbnail text-center d-none d-md-block">
                {/* <FrameworkSvg /> */}
                <img
                  className="radius "
                  src={MissionStatementFull}
                  alt="Images"
                />
              </div>
              <div className="thumbnail text-center d-block d-md-none">
                <img
                  className="radius w-100"
                  src={MissionStatementMobile}
                  alt="Images"
                />
              </div>
            </div>
          </div>
        </ScrollAnimation>

        {/* Start Introduction / Definition Area  */}
        <div
          id="Introduction"
          className="service-area rn-section-gapBottom mt--50 pb--25"
        >
          <div className="container">
            <div className="row align-items-center text-center text-lg-start">
              <div className="col-lg-4">
                <div className="content">
                  <h3 className="title">Introducing HappyHomes</h3>
                </div>
              </div>
              <div className="col-lg-8">
                <p className="mb--10">
                  We start by looking at a home differently— as a place where
                  love, hope, and dreams eventuate— which any other needs are
                  present for life. Philosophically, we believe that everyone
                  deserves a place to call, home. Home is a starting point for a
                  better future. Because a home of your own isn’t just a resting
                  place. It’s freedom, and it’s security. It’s a legacy—and a
                  chance to build wealth for everyone who comes after you.
                </p>
                <p>
                  Indonesia’s housing needs are vast. Projections of urban
                  population growth highlight the housing need for 780,000 new
                  household formations per year until 2045 while tackling an
                  existing ownership backlog of 12.1 million units and improving
                  millions of substandard homes. Therefore, HappyHomes is
                  applying a tech-forward approach to solve this long unsettled
                  matter.
                </p>

                <ScrollAnimation
                  animateIn="fadeInUp"
                  animateOut="fadeInOut"
                  animateOnce={true}
                  className="button-group"
                >
                  <a
                    className="btn-default btn-small btn-icon"
                    href="https://drive.google.com/file/d/1kVCrH72LP-s1zYHVFXArQ13ZmGpB0thX/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Read Full Fact Sheet Here{" "}
                    <i className="icon">
                      <FiArrowRight />
                    </i>
                  </a>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
        {/* End Introduction / Definition Area  */}

        {/* Start Framework / Business Phase Area  */}
        <div className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Business Transformation"
                  description="Through iterative in improving solutions at scale and significant investment in technology infrastructure, we have been transforming our business model from providing affordable housing, to enabling homeownership through creative financing, up until enhancing future homeowner’s lifestyle."
                />
              </div>
            </div>
            <ServiceThree
              serviceStyle="service__style--2"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Framework / Business Phase Area  */}

        <Separator />

        {/* Start Background / The Problems Area  */}
        <div id="Background" className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row  justify-content-md-center">
              <div className="col-lg-10">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Background"
                  title="The Problems"
                  description="Affordable Housing is a worldwide significant issue that will lead to crisis. “Developing Countries as Indonesia struggle with only the wealthiest, 20 percent, of households can afford housing in the formal commercial market, based on the estimated average housing cost of IDR 440 million (US$33,000)”, says World Bank."
                />
              </div>
            </div>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOut="fadeInOut"
              animateOnce={true}
            >
              <div className="thumbnail">
                <div className="d-none d-lg-block">
                  <img
                    className="radius w-80"
                    src={ImgProblemFull}
                    // src="../assets/images/segment2-01.png"
                    alt="Images"
                  />
                </div>
                <div className="d-block d-lg-none">
                  <img
                    className="radius w-100"
                    src={ImgProblemMobile1}
                    alt="Images"
                  />
                  <img
                    className="radius w-100"
                    src={ImgProblemMobile2}
                    alt="Images"
                  />
                </div>
              </div>
            </ScrollAnimation>
            {/* <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--20 rbt-border"
              textAlign="text-start"
            /> */}
          </div>
        </div>
        {/* End Background / The Problems Area  */}

        <Separator />

        {/* Start Framework / How HappyHomes Area  */}
        <div id="Framework" className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Framework"
                  title="How HappyHomes Revolutionize The Industry"
                  description="Disrupting the existing fragmented industry becoming HappyHomes aggregated demand"
                />
              </div>
            </div>
            <div className="thumbnail text-center row row--15">
              <img
                className="radius col-lg-6 col-12 "
                src={ImgHowHH1}
                alt="Images"
              />
              <img
                className="radius col-lg-6 col-12 "
                src={ImgHowHH2}
                alt="Images"
              />
            </div>
            {/* <ServiceOne
serviceStyle="service__style--1 bg-color-blackest radius mt--20 rbt-border"
textAlign="text-start"
/> */}
          </div>
        </div>
        {/* End Framework / How HappyHomes Area  */}

        <Separator />

        {/* Start Framework2 / Business Flow Area  */}
        <div id="Framework2" className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Framework"
                  title="HappyHomes Business Model"
                  description="Our business model generates sustainability and scalability of homeownership enablement. Collecting the demand before starting a new project makes HappyHomes a low-risk business model. It solves three primary real estate pain points, which are (1) capital-intensive limitation, (2) low-demand risk that locks the company cash flow off to continue operations, and (3) unreliable qualified massive unit delivery."
                />
              </div>
            </div>
            <div className="thumbnail text-center">
              {/* <FrameworkSvg /> */}
              <img className="radius w-70 " src={imgBusinesFlow} alt="Images" />
            </div>
            {/* <ServiceOne
              serviceStyle="service__style--1 bg-color-blackest radius mt--20 rbt-border"
              textAlign="text-start"
            /> */}
          </div>
        </div>
        {/* End Framework2 / Business Flow Area  */}

        <Separator />

        {/* Start Crisis / Business Journey Area */}
        <div id="Crisis" className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Framework"
                  title="The Three Main Solutions on The Business Model"
                  description=""
                />
              </div>
            </div>
            <TabSolution />
          </div>
        </div>
        {/* Start Crisis / Business Journey Area */}

        <Separator />

        {/* Start Corporate Structure Area  */}
        <div id="Structure" className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Structure"
                  title="Corporate Structure"
                  description="HappyHomes Holdings Ltd (“HH”) was incorporated in the State of Nevada and prepared for public listing in 2024. The Company owns 100% of the equity interests of PT Rumah Bahagia Indonesia (“HHInd”) in Indonesia"
                />
              </div>
            </div>
            <div className="thumbnail text-center">
              {/* <FrameworkSvg /> */}
              <img
                className="radius col-lg-4 col-md-6 col-sm-6 col-12 "
                src={ImgStructure}
                alt="Images"
              />
            </div>
          </div>
        </div>
        {/* End Corporate Structure Area  */}

        <Separator />

        {/* Start Traction Area  */}
        <div id="Traction" className="our-counterup-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Traction"
                  title="Milestone within a year "
                  description=""
                />
                {/* Start Elements Area  */}
                <div className="rwt-counterup-area ptb--60">
                  <div className="col-lg-12">
                    <CounterUpFour
                      column="col-lg-3 col-md-6 col-sm-6 col-12"
                      counterStyle="counter-style-4"
                      textALign="text-center"
                    />
                  </div>
                </div>
                {/* End Elements Area  */}
              </div>
            </div>
          </div>
        </div>
        {/* End Traction Area  */}

        <Separator />

        {/* Start Milestone / Business Journey Area */}
        <div id="Milestone" className="rwt-timeline-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Milestone"
                  title="The Way We Got Here and Where We are Heading Ahead"
                />
              </div>
            </div>
            <div className="thumbnail text-center d-none d-md-block">
              {/* <FrameworkSvg /> */}
              <img className="radius " src={ImgMilestoneFull} alt="Images" />
            </div>
            <div className="thumbnail text-center d-block d-md-none">
              <img className="radius w-100" src={ImgMilestone1} alt="Images" />
              <img className="radius w-100" src={ImgMilestone2} alt="Images" />
            </div>
          </div>
        </div>
        {/* Start Milestone / Business Journey Area */}

        <Separator />

        {/* Start Managements Area  */}
        <div id="Managements" className="rwt-team-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Management Team"
                  description=""
                />
              </div>
            </div>
            <SplitMngmnt1 />
            <SplitMngmnt2 />
            <TeamOne
              column="col-lg-4 col-md-6 col-12 mt--30"
              teamStyle="team-style-default"
            />
          </div>
        </div>
        {/* End Managements Area  */}

        <Separator />

        {/* Start Offering Under Reg D Area  */}
        {/* <div id="Offering" className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Structure"
                  title="Investment Offering Under Reg D"
                  description="If you desired to use DealMaker platform as the gateway to invest in HappyHomes, it is live now. Reg D and Reg S rules securities offering to the general public without having to register with the SEC, to accredited investors and the company verifies that the investors are accredited."
                />
              </div>
            </div>
            <div className="thumbnail text-center">
              <img
                className="radius col-lg-4 col-md-6 col-sm-6 col-12 "
                src="https://i.ibb.co/s9pzL71/reg-d.jpg"
                alt="Images"
              />
            </div>
          </div>
        </div> */}
        {/* End Offering Under Reg D Area  */}

        {/* Start Service Area  */}
        <div className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Investment Offering Under Reg D & Reg S"
                  description="If you desired to use DealMaker platform as the gateway to invest in HappyHomes, it is live now 
                <a
                  href=https://google.com
                  target={`_blank`}
                  rel={`noreferrer`}
                >
                  here
                </a>
                . Reg D and Reg S rules securities offering to the general public without having to register with the SEC, to accredited investors and the company verifies that the investors are accredited."
                />
              </div>
            </div>
            <ServiceThreeRegD
              serviceStyle="service__style--2"
              textAlign="text-center"
            />
          </div>
        </div>
        {/* End Service Area  */}

        <Separator />

        {/* Start Investment Flow Area  */}
        <div className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Invest in HappyHomes"
                  description=""
                />
              </div>
            </div>
            <ServiceThreeInvestFlow
              serviceStyle="service__style--2"
              textAlign="text-center"
            />
            {/* <p className="fs-4 fst-italic text-center pt--50">* Mandatory</p> */}
          </div>
        </div>
        {/* End Investment Flow Area  */}

        <Separator />

        {/* Start Attachment Area  */}
        {/* <div id="Attachment" className="rwt-tab-area rn-section-gap">
          <div className="container">
            <div className="row mb--40">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle=""
                  title="Investor Relations"
                  description=""
                />
              </div>
            </div>
            <AboutFour image="./images/about/contact-image.jpg" />
          </div>
        </div> */}
        {/* End Attachment Files Area  */}

        <Separator />

        {/* Start FAQ Area  */}
        <div id="FAQ" className="rn-accordion-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="FAQ"
                  title="Frequently Asked Questions"
                  description=""
                />
              </div>
            </div>
            <div className="row mt--35 row--20">
              <div className="col-lg-10 offset-lg-1">
                <AccordionOne customStyle="rn-accordion-03" />
              </div>
            </div>
          </div>
        </div>
        {/* End FAQ Area  */}

        <Separator />

        {/* Start Press Release Area  */}
        {/* <div id="Press Release" className="rn-service-area rn-section-gap ">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Press Release"
                  title="Press Release"
                  description=""
                />
              </div>
            </div> */}

        {/* <div className="thumbnail text-center">
              <FrameworkSvg />
              <img
                className="radius col-md-10 col-sm-6 col-12 "
                src="https://i.ibb.co/NmnH3sB/key-metrics-01-2-1.png"
                alt="Images"
              />
            </div> */}
        {/* </div>
        </div> */}
        {/* End Press Release Area  */}

        <Separator />

        {/* Start Attachment Area  */}
        {/* <div id="Attachment" className="rn-service-area rn-section-gap">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <SectionTitle
                  textAlign="text-center"
                  radiusRounded=""
                  subtitle="Attachment"
                  title="Investor Relations"
                  description=""
                />
              </div>
            </div>
            <ServiceOne
              serviceStyle="service__style--1 icon-circle-style"
              textAlign="text-center"
            />
          </div>
        </div> */}
        <FooterTwo />
      </main>
    </>
  );
};

export default LandingPage;
