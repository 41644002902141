import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "https://i.ibb.co/nstQ2yh/flow-03.jpg",
    title: "Step 1:<br/>Register to the Q&A Session (optional)",
    description:
      "This is optional. If you have further questions on how we are making our mission come true, we will be welcoming you on the Q&A Session.",
  },
  {
    image: "https://i.ibb.co/BL43bvv/flow-04.jpg",
    title: "Step 2:<br/>Sign the Preliminary Agreements",
    description:
      "Sign the Non-Disclosure Agreement and the Letter of Investment Intent.",
  },
  {
    image: "https://i.ibb.co/FqmZtth/flow-05.jpg",
    title: "Step 3:<br/>Access the Data Room (optional)",
    description:
      "Access all of the confidential documents on our previous projects, corporates, tax compliance, and others in this stage.",
  },
  {
    image: "https://i.ibb.co/S02Ms9b/flow-06.jpg",
    title: "Step 4:<br/>Make the Deal",
    description:
      "Term Sheet & Share Subscription Agreement Signing, then fund disbursement.",
  },
];
const ServiceThreeInvestFlow = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper justify-content-center">
      {ServiceList.map((val, i) => (
        <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className=" image">
                  <img className="radius" src={val.image} alt="card Images" />
                </div>
                <div className="content">
                  <h4 className="title">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceThreeInvestFlow;
