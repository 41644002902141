import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from "react-icons/fi";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import VideoOne from "../video/VideoOne";

const TabTwo = () => {
  return (
    <div>
      <div className="row">
        <div className="col-lg-12">
          <Tabs>
            <div className="row row--30 align-items-center">
              <div className="col-lg-5">
                <VideoOne />
              </div>
              <div className="col-lg-7 mt_md--40 mt_sm--40">
                <div className="rn-default-tab style-two">
                  <div className="tab-button-panel">
                    <TabList className="tab-button">
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Investor Relations</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Transcript of Business Presentation</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Read the Full Fact Sheet</button>
                        </div>
                      </Tab>
                      <Tab>
                        <div className="rn-tab-button">
                          <button>Investment Highlights</button>
                        </div>
                      </Tab>
                    </TabList>
                  </div>

                  <div className="tab-content-panel">
                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            Contains HappyHomes Info Memo for further
                            understanding on the business, risk, opportunity,
                            team, and others.
                          </p>
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                            className="button-group"
                          >
                            <a
                              className="btn-default btn-small btn-icon"
                              href="https://www.google.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Read The Investor Relations Here{" "}
                              <i className="icon">
                                <FiArrowRight />
                              </i>
                            </a>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            If you finding hard to access the Webcast, you can
                            have the Webcast Transcript.
                          </p>
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                            className="button-group"
                          >
                            <a
                              className="btn-default btn-small btn-icon"
                              href="https://www.google.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Read The Transcript of Business Presentation Here{" "}
                              <i className="icon">
                                <FiArrowRight />
                              </i>
                            </a>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>Read the Full Fact Sheet</p>
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                            className="button-group"
                          >
                            <a
                              className="btn-default btn-small btn-icon"
                              href="https://drive.google.com/file/d/1Ej1LeSUoW26C9VjP5_RdC2O2faypj4ko/view?usp=share_link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Read The Full Fact Sheet Here{" "}
                              <i className="icon">
                                <FiArrowRight />
                              </i>
                            </a>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </TabPanel>

                    <TabPanel>
                      <div className="rn-tab-content">
                        <div className="inner">
                          <p>
                            You will have a summary sheet of the HappyHomes
                            Financial Performance and Valuation. We will send
                            the fully report after the Q&A session.
                          </p>
                          <ScrollAnimation
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}
                            className="button-group"
                          >
                            <a
                              className="btn-default btn-small btn-icon"
                              href="https://www.google.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Read The Investment Highlights Here{" "}
                              <i className="icon">
                                <FiArrowRight />
                              </i>
                            </a>
                          </ScrollAnimation>
                        </div>
                      </div>
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TabTwo;
