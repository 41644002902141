import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const TimelineData = [
  {
    id: "1",
    year: "2019",
    title: "Commercially",
    title2: "Active",
    description:
      "Acquired 3 ha Land with 3 projects and value worth USD 60 Million expected to be finished in 2021",
  },
  {
    id: "2",
    year: "2020",
    title: "Secure Angel ",
    title2: "Investment Funding",
    description:
      "Received convertible loan investment amounting USD 104k each from Ridwan Malawi and Indrajaya Kencana securing 15 ha Land. The investment later converted into 15% equity each.and second investment from Singapore Investors for USD 185k for securing additional 10 ha land",
  },
  {
    id: "3",
    year: "2021",
    title: "Foreign Investment",
    title2: "from Middle East Company",
    description:
      "Realtegic received investment amounting USD 3.7 Mio from Bazara Group from which USD 1.3 Mio investment for 40% equity securing 35 ha land",
  },
  {
    id: "4",
    year: "2022",
    title: "Strategic partnership deal ",
    title2: "",
    description:
      "With Syari'al Real Estate Property (1,050 projects, 1,100 Hectars) & Surabaya AeroCity Development Project (15,000 hectars)",
  },
  {
    id: "5",
    year: "2023",
    title: "Go Public in Nasdaq",
    title2: "",
    description: "",
  },
];

const TimelineTwo = ({ classVar }) => {
  return (
    <div className="timeline-style-two bg-color-blackest">
      <div className="row row--0">
        {/* Start Single Progress */}
        {TimelineData.map((data, index) => (
          <div
            className={`col-lg-4 col-md-4 rn-timeline-single ${classVar}`}
            key={index}
          >
            <div className="rn-timeline">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h6 className="title mb--0">{data.year}</h6>
                <h6 className="title mb--0">{data.title}</h6>
                <h6 className="title">{data.title2}</h6>
              </ScrollAnimation>
              <div className="progress-line">
                <div className="line-inner"></div>
              </div>
              <div className="progress-dot">
                <div className="dot-level">
                  <div className="dot-inner"></div>
                </div>
              </div>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">{data.description}</p>
              </ScrollAnimation>
            </div>
          </div>
        ))}
        {/* End Single Progress */}
      </div>
    </div>
  );
};
export default TimelineTwo;
