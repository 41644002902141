import React from "react";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    image: "https://i.ibb.co/1dVCfxy/Asset-Image-01.png",
    title: "Phase 1:<br/>Provide a scalable fast-standardized unit",
    description:
      "Phase 1 is the beginning of our awesome journey. Location and price with fast-standardized delivery is the key to our competitive advantage and unique selling point.",
  },
  {
    image: "https://i.ibb.co/fQLtCQJ/Asset-Image-02.png",
    title: "Phase 2:<br/>Enable homeownership through creative financing",
    description:
      "Phase 2 is where we found a way to disrupt the industry using an innovative financing scheme that allows us to access sustainable, unlimited, and low-cost of working capital to enable homeownership.",
  },
  {
    image: "https://i.ibb.co/Bgkgfv5/Asset-Image-03.png",
    title: "Phase 3:<br/>Enhance future homeowner’s lifestyle",
    description:
      "Phase 3 is when we unlock the biggest of the recurring revenue stream by providing the future homeowner’s lifestyle and enhancing their quality of life.",
  },
];
const ServiceThree = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper justify-content-center">
      {ServiceList.map((val, i) => (
        <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <div className="inner">
                <div className="image">
                  <img src={val.image} alt="card Images" />
                </div>
                <div className="content">
                  <h4 className="title">
                    <Link
                      to="#service"
                      dangerouslySetInnerHTML={{ __html: val.title }}
                    ></Link>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceThree;
