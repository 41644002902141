import React from "react";
import VideoTwo from "../video/VideoTwo";
import { FiArrowRight, FiCheck } from "react-icons/fi";
import VideoOne from "../video/VideoOne";
import ScrollAnimation from "react-animate-on-scroll";

const PopupData = [
  {
    id: "01",
    image:
      "https://i.ytimg.com/vi/6FgF7hKwcao/hq720.jpg?sqp=-oaymwE2CNAFEJQDSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB1AaAAuADigIMCAAQARhFIGAoZTAP&rs=AOn4CLDCQeQfXFiBp1nNmtQE3OrcrQsZNA",
    popupLink: ["https://www.youtube.com/watch?v=6FgF7hKwcao"],
  },
];

const AboutFour = ({ image }) => {
  return (
    <div className="about-area about-style-4 rn-section-gap">
      <div className="container">
        <div className="row row--40 align-items-center">
          <div className="col-lg-6">
            <VideoOne />
          </div>
          <div className="col-lg-6 mt_md--40 mt_sm--40">
            <div className="content">
              <div className="inner">
                {/* <h3 className="title">
                  Doob specializes in <br /> small{" "}
                  <strong>Corporate Business.</strong>
                </h3> */}
                <ul className="feature-list">
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Transcript</h4>
                      <p className="text">
                        Access to the business presentation transcript on the
                        video.
                      </p>
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                        className="button-group"
                      >
                        <a
                          className="btn-default btn-small btn-icon"
                          href="https://www.google.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read The Transcript of Business Presentation Here{" "}
                          <i className="icon-btn">
                            <FiArrowRight />
                          </i>
                        </a>
                      </ScrollAnimation>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Read the Full Fact Sheet</h4>
                      <p className="text">
                        HappyHomes business summary including the background,
                        business process, milestones, and financial performance
                        are described in the fact sheet.
                      </p>
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                        className="button-group"
                      >
                        <a
                          className="btn-default btn-small btn-icon"
                          href="https://drive.google.com/file/d/1Ej1LeSUoW26C9VjP5_RdC2O2faypj4ko/view?usp=share_link"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read The Full Fact Sheet Here{" "}
                          <i className="icon-btn">
                            <FiArrowRight />
                          </i>
                        </a>
                      </ScrollAnimation>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <FiCheck />
                    </div>
                    <div className="title-wrapper">
                      <h4 className="title">Investment Highlights</h4>
                      <p className="text">
                        HappyHomes investment highlights to figure the
                        opportunities by investing in the business
                      </p>
                      <ScrollAnimation
                        animateIn="fadeInUp"
                        animateOut="fadeInOut"
                        animateOnce={true}
                        className="button-group"
                      >
                        <a
                          className="btn-default btn-small btn-icon"
                          href="https://www.google.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Read The Investment Highlights Here{" "}
                          <i className="icon-btn">
                            <FiArrowRight />
                          </i>
                        </a>
                      </ScrollAnimation>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutFour;
