import React from "react";
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import { BsFileEarmarkPdf, BsMic } from "react-icons/bs";
import { BiMessageSquareDetail } from "react-icons/bi";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
  {
    icon: <BiMessageSquareDetail />,
    title: "Press Release",
    description:
      "Contains HappyHomes Info Memo for further understanding on the business, risk, opportunity, team, and others.",
  },
  {
    icon: <BsMic />,
    title: "Web Cast",
    description:
      "Listen to the discussion led by our CEO on how HappyHomes ability to revolutionize the affordable industry, starting in Indonesia.",
  },
  {
    icon: <BsFileEarmarkPdf />,
    title: "Transcript",
    description:
      "If you finding hard to access the Webcast, you can have the Webcast Transcript.",
  },
  {
    icon: <BsFileEarmarkPdf />,
    title: "Financial Performance & Valuation Report",
    description:
      "You will have a summary sheet of the HappyHomes Financial Performance and Valuation. We will send the fully report after the Q&A session.",
  },
];

const ServiceOne = ({ textAlign, serviceStyle }) => {
  return (
    <div className="row row--15 service-wrapper">
      {ServiceList.map((val, i) => (
        <div className="col-lg-3 col-md-6 col-sm-6 col-12" key={i}>
          <ScrollAnimation
            animateIn="fadeInUp"
            animateOut="fadeInOut"
            animateOnce={true}
          >
            <div className={`service ${serviceStyle} ${textAlign}`}>
              <a
                href="https://www.google.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="icon">{val.icon}</div>
                <div className="content">
                  <h4 className="title w-600">
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {val.title}
                    </a>
                  </h4>
                  <p
                    className="description b1 color-gray mb--0"
                    dangerouslySetInnerHTML={{ __html: val.description }}
                  ></p>
                </div>
              </a>
            </div>
          </ScrollAnimation>
        </div>
      ))}
    </div>
  );
};
export default ServiceOne;
