import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const AccordionOne = ({ customStyle }) => {
  return (
    <Accordion
      className={`rn-accordion-style ${customStyle}`}
      defaultActiveKey="0"
    >
      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="0">
            Where are HappyHomes corporate headquarters?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            HappyHomes is registered in Nevada , USA and will be headquartered
            there. We are temporarily centered in Indonesia.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="1">
            How to invest in HappyHomes?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body>
            HappyHomes shares are traded under Rule 506(c) of Regulation D rules
            as part of Pre-IPO Fundraising via{" "}
            <a href={`https://google.com`} target={`_blank`} rel={`noreferrer`}>
              {" "}
              DealMaker.
            </a>{" "}
            You may also conctact us directly to{" "}
            <a
              target="_blank"
              href="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=admin@happyhomes.id"
              data-rb-event-key="https://mail.google.com/mail/?view=cm&amp;fs=1&amp;tf=1&amp;to=admin@happyhomes.id"
              rel="noreferrer"
            >
              {" "}
              admin@happyhomes.id
            </a>{" "}
            or click the Whatsapp Icon for the next step.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="2">
            How can I get HappyHomes corporate performance report and the
            audited financial statements?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body>
            Once the preliminary agreements are signed, we will welcome
            investors candidate to our data room.
          </Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="3">
            When does HappyHomes fiscal's year end?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body>HappyHomes fiscal's year end in September 30</Card.Body>
        </Accordion.Collapse>
      </Card>

      <Card>
        <Card.Header>
          <Accordion.Toggle as={Button} variant="link" eventKey="4">
            What if there is question that have not been answered?
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body>
            You can email us at admin@happyhomes.id or register to the Q&A
            Session{" "}
            <a
              href="https://calendly.com/happyhomesqna/30min"
              target="_blank"
              rel="noreferrer"
            >
              {"here >>>"}
            </a>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};
export default AccordionOne;
