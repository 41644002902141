import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import { FiPlay } from "react-icons/fi";

const PopupContent = {
  image:
    "https://i.ytimg.com/vi/6FgF7hKwcao/hq720.jpg?sqp=-oaymwE2CNAFEJQDSFXyq4qpAygIARUAAIhCGAFwAcABBvABAfgB1AaAAuADigIMCAAQARhFIGAoZTAP&rs=AOn4CLDCQeQfXFiBp1nNmtQE3OrcrQsZNA",
  popupLink: ["https://www.youtube.com/watch?v=6FgF7hKwcao"],
};

function VideoOne() {
  const [toggler, setToggler] = useState(false);
  return (
    <div className="row row--15">
      <div className="col-12 ">
        <div className="video-popup icon-center">
          <div className="thumbnail">
            <img
              className="radius-small"
              src={`${PopupContent.image}`}
              alt="Corporate Image"
            />
          </div>
          <div className="video-icon">
            <button
              className="btn-default rounded-player"
              onClick={() => setToggler(!toggler)}
            >
              <span>
                <FiPlay />
              </span>
            </button>
          </div>
          <FsLightbox toggler={toggler} sources={PopupContent.popupLink} />
        </div>
      </div>
    </div>
  );
}
export default VideoOne;
