import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const SplitMngmnt2 = () => {
  return (
    <div className="rn-splite-style m-5">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-8 col-xl-8 col-12">
            <div className="" style={{ textAlign: "justify" }}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  Gito’s entrepreneurship journey began in 2014 by setting up a
                  new own company called Cartenz. It is an IT company that
                  provides e-Government software and helps many government
                  institutions increase their revenue and improve their digital
                  public services. More than 160 of 500 local governments in
                  Indonesia are Cartenz's clients. The biggest project that
                  Cartenz is setting up is Jakarta Smart City through Public
                  Private Partnership (PPP), with an amount of USD 800+ million.
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  Through excellent leadership, Cartenz has become the best
                  e-Government provider in Indonesia. Leading and raising funds
                  of USD 10 million in 2019 from a publicly listed company in
                  Malaysia.
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  Gito continues his journey by setting up HappyHomes to capture
                  the huge opportunity from housing issues in his country. Now,
                  he is active as the CEO of HappyHomes.
                </p>
              </ScrollAnimation>
            </div>
          </div>
          <div className="col-lg-4 col-xl-4 col-12">
            <div className="" style={{ textAlign: "justify" }}>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h5 className="subtitle mb--10">FORMAL EDUCATION</h5>
                <p className="description mb--10">
                  MBA Candidate, University of Manchester Sep 2022 - Now
                </p>
                <p className="description mb--10">
                  Harvard Business School - Owner/President Management Program
                  Executive Education Boston 2023 - Now
                </p>
                <p className="description mb--10">
                  Institute Technology Bandung (ITB), PhD Candidate of School
                  Business Management Jakarta Aug 2020 – Now
                </p>
                <p className="description ">
                  University of Indonesia, Master of Information Technology
                  Jakarta Sep 2012 – Dec 2014
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h5 className="subtitle mb--10">INFORMAL EDUCATION</h5>
                <p className="description mb--10">
                  Financial Modeling & Valuation Analyst Certification,
                  Corporate Finance Institute, USA Aug 2019 - Aug 2019
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitMngmnt2;
