import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const SplitMngmnt1 = () => {
  return (
    <div className="rn-splite-style">
      <div className="split-wrapper">
        <div className="row no-gutters radius-10 align-items-center">
          <div className="col-lg-3 col-xl-4 col-12">
            <div className="thumbnail image-left-content">
              <img
                src="https://i.ibb.co/RzC7wsy/Gito-Wahyudi.png"
                alt="split Images"
              />
            </div>
          </div>
          <div className="col-lg-9 col-xl-8 col-12">
            <div className="">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h4 className="title mb--0">Gito Wahyudi</h4>
                <h5 className="subtitle theme-gradient">CEO & Founder</h5>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description" style={{ textAlign: "justify" }}>
                  Gito is a successful entrepreneur who received a scholarship
                  from the Government of Indonesia to study in the State College
                  of Accounting owned by the Ministry of Finance, serving the
                  country by being a civil servant for almost ten years as well
                  as receiving a role as a Lead IT Architect.
                </p>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p className="description">
                  Gito graduated with an information technology (IT) major in
                  both bachelor’s and Master's degrees and presently studying
                  for a Phd. degree in Science in Management.
                </p>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplitMngmnt1;
