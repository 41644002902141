import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";

const Data = [
  {
    countNum: "100k+",
    countTitle: "Customer Contacts",
  },
  {
    countNum: "2.5k+",
    countTitle: "Active Bookings",
  },
  {
    countNum: "USD 15.3k",
    countTitle: "Average Unit Price",
  },
  {
    countNum: "500+",
    countTitle: "Units Built",
  },
  {
    countNum: "USD 8mio",
    countTitle: "Revenue",
  },
  {
    countNum: "USD 53",
    countTitle: "Customer Acquisition Cost",
  },
  {
    countNum: "165+ Ha",
    countTitle: "Land Secured",
  },
  {
    countNum: "Zero",
    countTitle: "Bank Loan",
  },
];
const CounterUpFour = ({ textALign, counterStyle, column }) => {
  return (
    <div className="row">
      {Data.map((data, index) => (
        <div className={`${column}`} key={index}>
          <div className={`count-box ${counterStyle} ${textALign}`}>
            <div className="count-number">{data.countNum}</div>
            {/* <TrackVisibility once>
              {({ isVisible }) =>
                isVisible && (
                  <div className="count-number">
                    {isVisible ? <CountUp end={data.countNum} /> : 0}
                    {isVisible ? <CountUp end={data.countNum} /> : 0}
                  </div>
                )
              }
            </TrackVisibility> */}
            <h5 className="counter-title">{data.countTitle}</h5>
          </div>
        </div>
      ))}
    </div>
  );
};
export default CounterUpFour;
